import { imageProcessor, resize } from 'ts-image-processor'
import { blobToBase64, base64ToBlob } from 'base64-blob'
import Alpine from 'alpinejs'

export const processImage = async (file) => {
  if (typeof file === 'object') {
    const processed = await blobToBase64(file)
      .then((base64) => imageProcessor.src(base64)
        .pipe(resize({ maxWidth: 720, maxHeight: 720 })))
    file = new File([await base64ToBlob(processed)], file.name)
  }

  return file
}

export const unwrapProxy = (maybeProxy) => {
  if (maybeProxy && typeof maybeProxy === 'object') {
    return Alpine.raw(maybeProxy).value
  }

  return maybeProxy
}

export const parseNumbers = (str) => {
  return str.replace(/[\u0660-\u0669]/g,
    d => d.charCodeAt() - 1632)
}

export const stripSpaces = (value) => value.replace(/\s+/g, '')

export const sum = (array, initialValue = 0) =>
  array.reduce((previousValue, currentValue) => previousValue + currentValue, initialValue)

export const getNumberValues = (selectors) =>
  Array
    .from(document.querySelectorAll(selectors))
    .map(item => Number(item.value))

// A bit of Rails magic. Submit a form having `remote: true` on it.
export const submitRemoteForm = (form) => {
  const submitEvent = new Event('submit', { bubbles: true })
  form.dispatchEvent(submitEvent)
}
